<template>
  <div class="content">
    <SendMail
        :manager-users="managerUsers"
        :is-spinner-show="isSpinnerShow"
    ></SendMail>
  </div>
</template>

<script>
import SendMail from "./SendMail";
import {mapState} from "vuex";

export default {
  name: "MailManagement",
  data() {
    return {
      store: {},
      route: "/stores",
      isBtnDisabled: true,
      isModalShow: false,
      managerUsers: [],
      isSpinnerShow: true,
    };
  },
  computed: {
    ...mapState(["isAdmin", "defaultTitle"]),
  },
  methods: {
    async getManagerUsers() {
      const route = `users?only_managers=1`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.managerUsers = response.data.data.users.data;
        this.managerUsers.push({ email: process.env.VUE_APP_INFO_AS_SALOON || 'info@as-saloon.com' });
        this.isSpinnerShow = false;
      }
    }
  },
  mounted() {
    if (!this.isAdmin) this.$router.push("/");
    this.getManagerUsers();
  },
  components: {
    SendMail
  },
};
</script>