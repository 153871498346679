<template>
  <div>
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.send.email") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="danger" @click="isModalShow = false">{{
            $t("cancel")
          }}</base-button>
        <base-button  type="secondary" @click="manageItem()">{{
            $t("confirmSend")
          }}</base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card>
          <form>
            <hr />
            <div v-if="!isSpinnerShow" class="col py-4">
              <div class="row">
                <div class="col-xl-9 col-lg-8 mb-3">
                  <div>
                    <label class="control-label">
                      {{ $t('manager') }}
                    </label>
                  </div>
                  <Select
                      multiple
                      class="select-primary w-100"
                      :disabled='(checkAllManager === true)'
                      :placeholder="$t('select.managers')"
                      v-model="selectedManagers"
                  >
                    <Option
                        v-for="manager in managerUsers"
                        class="select-primary"
                        :value="manager.email"
                        :label="manager.email"
                        :key="manager.id"
                    >
                    </Option>
                  </Select>
                </div>
                <div class="col-xl-3 col-lg-4 pb-4 pt-lg-4 pt-1">                  
                  <base-checkbox v-model="checkAllManager"  class="col-12">{{ $t("sendAllManager") }}</base-checkbox>
                </div>
                <div class="col-12 mb-3">
                  <base-input
                      inputType="text"
                      v-model="subject"
                      :placeholder="$t('subject')"
                      :label="`${$t('subject')}*`"
                      required
                  ></base-input>
                </div>
                <div class="col-12 mb-4">
                  <Editor
                        @valueChange="onFieldsChange"
                  />
                  <p class="text-danger">(*){{ $t("notificationEmail") }} </p>
                </div>
                <div v-if="isAdmin" class="col-12 col-sm-4">
                  <base-button :disabled="isBtnDisabled" @click="isModalShow = true" type="success">
                    {{ $t("sendEmail") }}
                  </base-button>
                </div>
              </div>
            </div>
            <Spinner v-else parentClasses="justify-content-center"></Spinner>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  BaseButton,
  BaseInput,
  Modal,
  Spinner,
} from "@/components/index";
import { mapActions, mapState } from "vuex";
import { Select, Option } from "element-ui";
import Editor from '@/components/Editor.vue'

export default {
  name: "SendMail",
  props: ["managerUsers","isSpinnerShow"],
  data() {
    return {
      route: "/users",
      isBtnDisabled: true,
      isModalShow: false,
        selectedManagers: [],
      contentEmail: "",
      checkAllManager: false,
      subject:""
    };
  },
  computed: {
    ...mapState(["isAdmin"]),
  },
  watch: {
   checkAllManager(newVal, oldVal) {      
      if(newVal === true){
          this.selectedManagers=[];
      }
    },
  },
  methods: {
    onFieldsChange(value) {
      this.contentEmail = value;
      if (value.length < 20) {
        this.disabledBtn(true);

      }else{
        this.disabledBtn(false);        
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      
    },
    manageItem() {
      const payload = this.createItem();      
      if (payload) this.sendEmail(payload);
    },
    createItem() {
      let payload = new FormData();
      this.selectedManagers.forEach((item) => payload.append("emails[]", item));
      payload.append("content", this.contentEmail);
      payload.append("subject", this.subject);
      payload.append("allManager", this.checkAllManager);
      return payload;
    },
    async sendEmail(payload) {
      this.disabledBtn(true);
      const route = `${this.route}/send-email-managers`;
      const response = await this.axios.post(route, payload);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
        this.isModalShow = false;
        this.selectedManagers= [];
        this.contentEmail= "";
        this.subject="";
      }
      this.disabledBtn(false);
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
  //  this.storeId = user.store.id;
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    Modal,
    Select,
    Option,
    Spinner,
    Editor
  },
};
</script>

