<template>
    <VueTrix inputId="textarea2" 
        :inputName="inputName" 
        v-model="editorContent"
        placeholder="Enter your content"
        @trix-file-accept="handleFile"
        @trix-attachment-add="handleAttachmentAdd"
        @trix-attachment-remove="handleAttachmentRemove"
        @trix-focus="handleEditorFocus"
        @trix-blur="handleEditorBlur"/>
</template>

<script>
import VueTrix from "vue-trix";

export default {
  name: 'Editor',
  components: {
    VueTrix
  },
  props: {
    inputName: {
      type: String,
      required: false,
      default: 'content'
    }
  },
  data () {
    return {
      editorContent: '',
      route: "/users",
    }
  },
  watch: {
    editorContent (value) {
        this.$emit('valueChange', value);
    }
},
  methods: {
    handleFile (file) {
     // console.log('Drop file:', file)
    },
    async   handleAttachmentRemove (file) {
      if (file.attachment.getAttributes().path) {
        const path =  file.attachment.getAttributes().path
        var formData = new FormData()
        formData.append( "path", path )

        const route = `${this.route}/delete-images-mail`;

        const response = await this.axios.post(route, formData);
      }
      
    },
    handleEditorFocus (event) {
     // console.log('Editor is focused:', event)
    },
    handleEditorBlur (event) {
     // console.log('Editor is lost focus', event)
    },
    async handleAttachmentAdd (event) {
      const SITEMAP_URL = process.env.VUE_APP_API_URL+"/storage/";
      let file = event.attachment.file;
      let key = this.createStorageKey(file)
      let formData = this.createFormData(key, file)
      const route = `${this.route}/upload-images-mail`;
      const config = {
          onUploadProgress: function(progressEvent) {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          event. attachment.setUploadProgress(percentCompleted);
       
        }
      }
      const response = await this.axios.post(route, formData, config);
      if (response && response.data.status === "success") {
        let attributes = {
          url: SITEMAP_URL + response.data.data,
          href: SITEMAP_URL + response.data.data,
          path: response.data.data,
        };
       
       event.attachment.setAttributes(attributes);
  
      }else{
       event.attachment.remove(file);
      }
    },
    createStorageKey(file) {
      var date = new Date()
      var day = date.toISOString().slice(0,10)
      var name = date.getTime() + "-" + file.name
      return [ "tmp", day, name ].join("/")
    },
    createFormData(key, file) {
      var data = new FormData()
      data.append("key", key)
      data.append("Content-Type", file.type)
      data.append("file", file)
      return data
    },

  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 15px 0;
}
.editor-wrapper,
.form-wrapper {
  max-width: 700px;
}

input,
button {
  margin: 10px 0;
  font-size: 15px;
}

input[type="text"] {
  width: 100%;
  padding: 10px 0;
}

button {
  min-width: 120px;
}
</style>